const DD = "DD";
const MMYYYY = "MM-YYYY";
const MMMDDYYYY = "MMM DD, YYYY";
const ddddMMMDDYYYY = "dddd, MMM DD, YYYY";
const MMM = "MMM";
const YYYY = "YYYY";
const YYYYMMDD = "YYYY-MM-DD";
const MMDDYYYY = "MM/DD/YYYY";
const MMMDYYYY = "MMM. D, YYYY";
const ddDYY = "ddd. M/D";
const MD = "M/D";
const ddddMMMMD = "dddd, MMMM D";
const MMMD = "MMM. D";
const MMMMYYYY = "MMMM YYYY";
const LT = "LT";
const LTS = "LTS";
const ll = "ll";
const LL = "LL";
const LLL = "LLL";
const TIME = "h:mm a";

export default {
  DD,
  MMYYYY,
  MMMDDYYYY,
  ddddMMMDDYYYY,
  MMM,
  YYYY,
  YYYYMMDD,
  MMDDYYYY,
  MMMDYYYY,
  MMMD,
  ddDYY,
  ddddMMMMD,
  LT,
  ll,
  LL,
  LLL,
  TIME,
  MMMMYYYY,
  LTS,
  MD,
};
