import React, { useState, useEffect, useContext } from "react";
import { Outlet, useParams, useSearchParams } from "react-router-dom";
import { CircularProgress, Box, Drawer } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Footer, TopNavBar, LeftDrawer, Breadcrumb } from "../components";
import schoolsService from "../service/schoolsService";
import messagesService from "../service/messagesService";
import notificationsService from "../service/notificationsService";
import {
  loader,
  rightDrawerContainer,
  rightDrawerContainerNoScroll,
} from "../components/sharedStyles";
import QuickBar from "../components/QuickBar";
import KlassForm from "../components/Klass/KlassForm";
import TermsForm from "../components/Schedules/Terms/TermsForm";
import SnackBarNotification from "../components/SnackBarNotification";
import AssignmentsForm from "../components/Assignments/AssignmentsForm";
import DayTemplatesForm from "../components/Schedules/DayTemplates/DayTemplatesForm";
import SchoolDaysForm from "../components/Schedules/SchoolDays/SchoolDaysForm";
import ClassScheduleForm from "../components/Schedules/ClassSchedules/ClassScheduleForm";
import ClassScheduleBulkEnroll from "../components/Schedules/ClassSchedules/ClassScheduleBulkEnroll";
import NewMessage from "../components/Messages/NewMessage/NewMessage";
import { QuickBarContext } from "../context/QuickBarContext";
import { storeCurrentSchoolId } from "../utils/constants/schoolIdStore";
import BeforeUnload from "../components/BeforeUnload";
import AddTranscriptRecord from "../components/StudentInfo/Transcripts/AddTranscriptRecord";
import CourseForm from "../components/Courses/CourseForm/CourseForm";

export default function MainLayout({ teacher, user, defaultSchoolSlug }) {
  const { i18n } = useTranslation();
  const [drawerStatus, setDrawerStatus] = useState(false);
  const [klassesDrawerStatus, setKlassesDrawerStatus] = useState(false);
  const [assignmentsDrawerStatus, setAssignmentsDrawerStatus] = useState(false);
  const [termsDrawerStatus, setTermsDrawerStatus] = useState(false);
  const [dayTemplateDrawerStatus, setDayTemplateDrawerStatus] = useState(false);
  const [schoolDaysDrawerStatus, setSchoolDaysDrawerStatus] = useState(false);
  const [bulkEnrollDrawerStatus, setBulkEnrollDrawerStatus] = useState(false);
  const [messageStudentId, setMessageStudentId] = useState(null);
  const [classScheduleDrawerStatus, setClassScheduleDrawerStatus] =
    useState(false);
  const [messageDrawerStatus, setMessageDrawerStatus] = useState(false);
  const [courseDrawerStatus, setCourseDrawerStatus] = useState(false);
  const [courseDrawerAction, setCourseDrawerAction] = useState(null);
  const [subjects, setSubjects] = useState([]);
  const [predefinedCourses, setPredefinedCourses] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [gpaScales, setGpaScales] = useState([]);
  const [snackBarOpen, setSnackBarOpen] = useState({
    open: false,
    message: null,
  });
  const [transcriptDrawerStatus, setTranscriptDrawerStatus] = useState(false);

  const [dataRollupSyncInitiated, setDataRollupSyncInitiated] = useState(false);
  const [hasUnreadMessages, setUnreadMessages] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState();
  const [currentSchool, setCurrentSchool] = useState(null);
  const params = useParams();
  const schoolId = params.school_id;
  const [searchParams] = useSearchParams();
  const schoolYearId = searchParams.get("school_year") || null;
  const quickBarContext = useContext(QuickBarContext);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerStatus(open);
  };

  const getSchool = async () => {
    const response = await schoolsService.fetchSchool(schoolId);

    setCurrentSchool(response.data);
  };

  const getUnreadMessages = async () => {
    const response = await messagesService.checkForUnreadMessages({
      params: { recipient_type: "Staff" },
    });
    setUnreadMessages(response.data);
  };

  const getUnreadNotifications = async () => {
    const response = await notificationsService.checkForUnreadNotifications();
    setUnreadNotifications(response);
  };

  const getLastDataRollupSynced = async () => {
    const response = await schoolsService.fetchSchool(schoolId);
    if (response.data?.last_data_rollup_synced_at) {
      const lastSynced = new Date(response.data.last_data_rollup_synced_at);
      const currentTime = new Date();
      const diff = currentTime - lastSynced;
      const minutes = Math.floor(diff / 60000);
      setDataRollupSyncInitiated(minutes <= 14);
    }
  };

  const handleKlassesDrawer = (status) => {
    setKlassesDrawerStatus(status);
  };

  const handleBulkEnrollDrawer = (status) => {
    setBulkEnrollDrawerStatus(status);
  };

  const handleAssignmentsDrawer = (status) => {
    if (!searchParams.get("term") || searchParams.get("term") === "select") {
      setSnackBarOpen({
        open: true,
        message: "Please select term.",
        severity: "info",
      });
      return;
    }
    setAssignmentsDrawerStatus(status);
    if (status) {
      quickBarContext.fireAssignmentDrawer(true);
    } else {
      quickBarContext.cleanAssignmentDrawer();
    }
  };

  const handleTermsDrawer = (status) => {
    setTermsDrawerStatus(status);
  };

  const handleDayTemplateDrawer = (status) => {
    setDayTemplateDrawerStatus(status);
  };

  const handleClassScheduleDrawer = (status) => {
    setClassScheduleDrawerStatus(status);
  };

  const handleSchoolDaysDrawer = (status) => {
    setSchoolDaysDrawerStatus(status);
  };

  const handleMessagesDrawer = (status, studentId) => {
    setMessageDrawerStatus(status);
    setMessageStudentId(studentId || null);
  };

  const handleTranscriptDrawer = (status) => {
    setTranscriptDrawerStatus(status);
  };

  const handleCourseDrawer = (status, course, action) => {
    setCourseDrawerStatus(status);
    setCourseDrawerAction(action);
  };

  useEffect(() => {
    getUnreadMessages();
    getUnreadNotifications();
    if (schoolId) {
      getLastDataRollupSynced();
    }
  }, [teacher, params]);

  useEffect(() => {
    getSchool();
    storeCurrentSchoolId(schoolId);
  }, [schoolId]);

  useEffect(() => {
    const helpscoutScript = document.createElement("script");
    helpscoutScript.type = "text/javascript";
    helpscoutScript.text =
      "window.Beacon('init', 'cb6d8f9b-d581-4cb3-9bab-fd664dbafbd3')";

    document.body.appendChild(helpscoutScript);

    return () => {
      document.body.removeChild(helpscoutScript);
    };
  }, []);

  // Let's guarantee that the default language is English.
  // This is important because the Main Portal is only available in English.
  // It also prevents some shared translated components from breaking.
  useEffect(() => {
    i18n.changeLanguage("en");
  }, [i18n]);

  if (teacher) {
    return (
      <>
        <QuickBar
          handleKlassesDrawer={handleKlassesDrawer}
          handleAssignmentsDrawer={handleAssignmentsDrawer}
          handleTermsDrawer={handleTermsDrawer}
          handleDayTemplateDrawer={handleDayTemplateDrawer}
          handleSchoolDaysDrawer={handleSchoolDaysDrawer}
          handleClassScheduleDrawer={handleClassScheduleDrawer}
          handleBulkEnrollDrawer={handleBulkEnrollDrawer}
          handleMessagesDrawer={handleMessagesDrawer}
          handleTranscriptDrawer={handleTranscriptDrawer}
          handleCourseDrawer={handleCourseDrawer}
          currentSchool={currentSchool}
        />
        <BeforeUnload />
        <div style={{ minWidth: "1400px" }}>
          {currentSchool ? (
            <>
              <LeftDrawer
                drawerStatus={drawerStatus}
                toggleDrawer={toggleDrawer}
                teacher={teacher}
                defaultSchoolSlug={defaultSchoolSlug}
                schoolId={schoolId}
              />
              <TopNavBar
                toggleDrawer={toggleDrawer}
                currentSchool={currentSchool}
                hasUnreadMessages={hasUnreadMessages}
                unreadNotifications={unreadNotifications}
                teacher={teacher}
                dataRollupSyncInitiated={dataRollupSyncInitiated}
                setDataRollupSyncInitiated={setDataRollupSyncInitiated}
              />
              <Box
                sx={{ minWidth: "1400px", minHeight: "calc(100vh - 200px)" }}
              >
                <Outlet
                  context={[
                    klassesDrawerStatus,
                    setSnackBarOpen,
                    teacher,
                    termsDrawerStatus,
                    dayTemplateDrawerStatus,
                    schoolDaysDrawerStatus,
                    classScheduleDrawerStatus,
                    bulkEnrollDrawerStatus,
                    user,
                    messageDrawerStatus,
                    handleMessagesDrawer,
                    currentSchool,
                    courseDrawerStatus,
                    courseDrawerAction,
                    setCourseDrawerAction,
                    subjects,
                    setSubjects,
                    predefinedCourses,
                    setPredefinedCourses,
                    departments,
                    setDepartments,
                    gpaScales,
                    setGpaScales,
                  ]}
                />
              </Box>
              <Breadcrumb />
              <Footer />

              <Drawer anchor="right" open={klassesDrawerStatus}>
                <Box sx={rightDrawerContainer}>
                  <KlassForm
                    teacherId={teacher.id}
                    schoolId={schoolId}
                    handleKlassesDrawer={handleKlassesDrawer}
                    setSnackBarOpen={setSnackBarOpen}
                    activeKlass={null}
                    schoolYearId={schoolYearId}
                    currentSchool={currentSchool}
                  />
                </Box>
              </Drawer>

              <Drawer anchor="right" open={assignmentsDrawerStatus}>
                <Box sx={rightDrawerContainer}>
                  <AssignmentsForm
                    schoolId={schoolId}
                    handleAssignmentsDrawer={handleAssignmentsDrawer}
                    termId={searchParams.get("term")}
                  />
                </Box>
              </Drawer>

              <Drawer anchor="right" open={termsDrawerStatus}>
                <Box sx={rightDrawerContainer}>
                  <TermsForm
                    schoolId={schoolId}
                    handleTermsDrawer={handleTermsDrawer}
                    schoolYearId={schoolYearId}
                    setSnackBarOpen={setSnackBarOpen}
                  />
                </Box>
              </Drawer>

              <Drawer anchor="right" open={dayTemplateDrawerStatus}>
                <Box sx={rightDrawerContainer}>
                  <DayTemplatesForm
                    schoolId={schoolId}
                    handleDayTemplateDrawer={handleDayTemplateDrawer}
                    setSnackBarOpen={setSnackBarOpen}
                  />
                </Box>
              </Drawer>

              <Drawer anchor="right" open={classScheduleDrawerStatus}>
                <Box sx={rightDrawerContainer}>
                  <ClassScheduleForm
                    schoolId={schoolId}
                    handleClassScheduleDrawer={handleClassScheduleDrawer}
                    setSnackBarOpen={setSnackBarOpen}
                  />
                </Box>
              </Drawer>

              <Drawer anchor="right" open={schoolDaysDrawerStatus}>
                <Box sx={rightDrawerContainer}>
                  <SchoolDaysForm
                    schoolId={schoolId}
                    handleSchoolDaysDrawer={handleSchoolDaysDrawer}
                    schoolYearId={schoolYearId}
                    setSnackBarOpen={setSnackBarOpen}
                  />
                </Box>
              </Drawer>

              <Drawer anchor="right" open={bulkEnrollDrawerStatus}>
                <Box sx={rightDrawerContainerNoScroll}>
                  <ClassScheduleBulkEnroll
                    schoolId={schoolId}
                    handleBulkEnrollDrawer={handleBulkEnrollDrawer}
                    setSnackBarOpen={setSnackBarOpen}
                    currentSchool={currentSchool}
                    schoolYearId={schoolYearId}
                  />
                </Box>
              </Drawer>

              <Drawer anchor="right" open={messageDrawerStatus}>
                <Box sx={rightDrawerContainer}>
                  <NewMessage
                    handleMessagesDrawer={handleMessagesDrawer}
                    schoolId={schoolId}
                    setSnackBarOpen={setSnackBarOpen}
                    messageDrawerStatus={messageDrawerStatus}
                    messageStudentId={messageStudentId}
                  />
                </Box>
              </Drawer>

              <Drawer anchor="right" open={transcriptDrawerStatus}>
                <Box sx={rightDrawerContainer}>
                  <AddTranscriptRecord
                    handleTranscriptDrawer={handleTranscriptDrawer}
                    setSnackBarOpen={setSnackBarOpen}
                  />
                </Box>
              </Drawer>

              <Drawer anchor="right" open={courseDrawerStatus}>
                <Box sx={rightDrawerContainer}>
                  <CourseForm
                    handleCourseDrawer={handleCourseDrawer}
                    subjects={subjects}
                    predefinedCourses={predefinedCourses}
                    departments={departments}
                    gpaScales={gpaScales}
                  />
                </Box>
              </Drawer>

              <SnackBarNotification
                message={snackBarOpen.message}
                open={snackBarOpen.open}
                severity={snackBarOpen.severity}
                handleClose={() => setSnackBarOpen(false)}
              />
            </>
          ) : (
            <CircularProgress sx={loader} size={100} />
          )}
        </div>
      </>
    );
  }
}
