import {
  Box,
  Button,
  IconButton,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import { useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import {
  displayInline,
  gridContainer,
  gridItemStudentInfo,
  gridItemTeacherInfo,
  gridItemTeacherInfoLabel,
  gridItemTeacherInfoPrincipalLabel,
  newMessageTo,
  newMessageName,
  newMessageBox,
  newMessageDesc,
  newMessageModal,
  newMessageSend,
  newMessageDescError,
  modalCloseIcon,
} from "./Home.styles";
import { danger, pt30, pt50 } from "../../sharedStyles";
import StudentInfoHomeClasses from "./Classes";
import StudentInfoHomeMessages from "./Messages";
import StudentInfoHomeGrades from "./Grades";
import Attachments from "../Messages/Attachments";
import SnackBarNotification from "../../SnackBarNotification";
import messagesService from "../../../service/messagesService";
import { newMessageIcon } from "../Messages/Messages.styles";

export default function StudentPortalHome() {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm();
  const [student] = useOutletContext();
  const setDisplay =
    ["TK", "K", "1", "2"].includes(student.grade_level) && "none";
  const [attachments, setAttachments] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [newMessageOpen, setNewMessageOpen] = React.useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState({
    open: false,
    message: null,
  });
  const [sendMessageLoader, setSendMessageLoader] = useState(false);
  const params = useParams();
  const schoolId = params.school_id;
  const maxFileSize = 20000000;
  const handleModalOpen = () => setNewMessageOpen(true);
  const handleModalClose = () => {
    setNewMessageOpen(false);
    reset();
    setUploadedFiles([]);
    setSendMessageLoader(false);
  };

  const handleFileUploads = (e) => {
    const files = [...uploadedFiles];
    Array.prototype.forEach.call(e.target.files, (file, index) => {
      const fileWithId = file;
      const fileId =
        uploadedFiles.length > 0 ? index + uploadedFiles.at(-1).id + 1 : index;
      fileWithId.id = fileId;
      files.push(fileWithId);
    });

    try {
      if (files) {
        setUploadedFiles(files);
      }
    } catch (err) {
      if (err.error === "sizeException") {
        setSnackBarOpen({
          message: err.message,
          open: true,
          severity: "error",
        });
      }
    }
  };

  function SizeException(message) {
    setSnackBarOpen({
      message,
      open: true,
      severity: "error",
    });
    setSendMessageLoader(false);
    return {
      message,
      error: "sizeException",
    };
  }

  const onSubmit = async (data) => {
    setSendMessageLoader(true);
    const formData = new FormData();

    const formParams = {
      subject: data.newMessageDescription.substring(0, 10),
      description: data.newMessageDescription,
      school_id: schoolId,
      sender_type: "Student",
    };

    Object.keys(formParams).map((keyName) =>
      formData.append(keyName, formParams[keyName])
    );

    formData.append(
      "recipients",
      JSON.stringify([
        { target: "single_teacher", teacher_id: student.teacher?.id },
      ])
    );

    Array.prototype.forEach.call(uploadedFiles, (file, index) => {
      if (uploadedFiles.length > 10)
        throw new SizeException("Maximum of 10 files are permitted.");
      if (file.size > maxFileSize)
        throw new SizeException(`The file ${file.name} is bigger than 20Mb.`);
      formData.append(`attachments[]`, uploadedFiles[index]);
    });

    const response = await messagesService.createMessage(formData);

    if (response.data) {
      setSnackBarOpen({
        open: true,
        message: "Message sent.",
      });

      setSendMessageLoader(false);
      handleModalClose();
    }
  };

  return (
    <>
      <Grid container sx={gridContainer}>
        <Grid item md={4} xs={4} sx={gridItemStudentInfo}>
          <Box sx={displayInline}>
            Hi {student.first_name} {student.last_name}!
          </Box>
        </Grid>
        <Grid item md={8} xs={8} sx={gridItemTeacherInfo}>
          {student.teacher && (
            <>
              <Box
                onClick={handleModalOpen}
                sx={displayInline}
                style={{ cursor: "pointer" }}
              >
                {student.teacher?.first_name} {student.teacher?.last_name}
              </Box>
              <Box sx={gridItemTeacherInfoLabel}>Homeroom Teacher</Box>
            </>
          )}

          {student.counselor_name && (
            <>
              <Box sx={displayInline}>{student.counselor_name}</Box>
              <Box sx={gridItemTeacherInfoLabel}>Counselor</Box>
            </>
          )}

          {student.school.principal && (
            <>
              <Box sx={displayInline}>{student.school.principal}</Box>
              <Box sx={gridItemTeacherInfoPrincipalLabel}>Principal</Box>
            </>
          )}
        </Grid>

        <Grid container sx={pt30} style={{ paddingLeft: "40px" }}>
          <Grid item md={8} xs={8}>
            <StudentInfoHomeClasses />
          </Grid>

          <Grid item md={0.5} xs={0.5} />

          <Grid item md={3.5} xs={3.5}>
            <StudentInfoHomeMessages />
          </Grid>
        </Grid>

        <Grid
          container
          sx={pt50}
          style={{ paddingLeft: "40px", display: setDisplay }}
        >
          <Grid item md={12}>
            <StudentInfoHomeGrades />
          </Grid>
        </Grid>
      </Grid>

      <Modal
        open={newMessageOpen}
        onClose={handleModalClose}
        aria-labelledby="new-message-title"
        aria-describedby="new-message-description"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={newMessageModal}>
            <Box sx={newMessageBox} id="new-message-title">
              <Typography sx={newMessageTo}>To:</Typography>
              <img
                alt="profile_image"
                src={student.teacher?.image_url?.url}
                style={{
                  borderRadius: "100px",
                  border: "6px solid #000",
                  width: "90px",
                  height: "90px",
                  objectFit: "cover",
                }}
              />
              <Typography sx={newMessageName}>
                {student.teacher?.first_name} {student.teacher?.last_name}
              </Typography>
              <IconButton
                aria-label="modal-close"
                onClick={handleModalClose}
                sx={modalCloseIcon}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <TextField
              autoFocus
              focused
              sx={newMessageDesc}
              id="new-message-description"
              placeholder="Type your message..."
              multiline
              variant="filled"
              rows={10}
              error={errors?.newMessageDescription && true}
              {...register("newMessageDescription", {
                required: {
                  value: true,
                  message: "This field is required.",
                },
              })}
            />
            {errors?.newMessageDescription && (
              <Typography sx={[danger, newMessageDescError]}>
                {errors?.newMessageDescription.message}
              </Typography>
            )}
            <Attachments
              handleFileUploads={handleFileUploads}
              attachments={attachments}
              setAttachments={setAttachments}
              uploadedFiles={uploadedFiles}
              setUploadedFiles={setUploadedFiles}
            />
            <Box sx={{ textAlign: "center" }}>
              <Button
                type="submit"
                disabled={sendMessageLoader}
                sx={newMessageSend}
                variant="contained"
                endIcon={<SendOutlinedIcon sx={newMessageIcon} />}
              >
                {sendMessageLoader ? "Sending message..." : "Send"}
              </Button>
            </Box>
          </Box>
        </form>
      </Modal>

      <SnackBarNotification
        message={snackBarOpen.message}
        open={snackBarOpen.open}
        severity={snackBarOpen.severity}
        handleClose={() => setSnackBarOpen(false)}
      />
    </>
  );
}
