import { tableCellClasses, tableRowClasses } from "@mui/material";

const guardianDrawer = {
  "& .MuiPaper-root": {
    backgroundColor: "white",
    width: "800px",
  },
};
const tableStyles = {
  minWidth: 650,
  [`& .${tableCellClasses.root}`]: {
    borderBottom: "none",
  },
  [`& .${tableRowClasses.root}:not(:last-child)`]: {
    borderBottom: "1px solid #d9d9d9",
  },
  "& .MuiTableCell-head": {
    color: "#8f8e8e",
    fontWeight: "500 !important",
  },
  "& .MuiTableCell-body": {
    color: "black",
    fontSize: 16,
  },
};

const dialogStyle = {
  ".MuiPaper-root": {
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
  },
};

const dialogTitleStyle = {
  fontSize: 26,
  color: "#C62828",
  fontWeight: 800,
  textAlign: "center",
};

const dialogContentStyle = { color: "#000000", textAlign: "center" };

const noButtonStyle = {
  backgroundColor: "#C62828",
  color: "#ffffff",
  minWidth: "150px",
  "&:hover": {
    backgroundColor: "#C62828",
    color: "#ffffff",
  },
};

const yesButtonStyle = {
  backgroundColor: "#000000",
  color: "#ffffff",
  minWidth: "150px",
  "&:hover": {
    backgroundColor: "#000000",
    color: "#ffffff",
  },
};

export {
  guardianDrawer,
  tableStyles,
  dialogStyle,
  dialogTitleStyle,
  dialogContentStyle,
  noButtonStyle,
  yesButtonStyle,
};
